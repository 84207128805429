import { Container } from '@everlywell/leaves';
import PrivacyPolicySection from 'components/PrivacyPolicySection';
import SectionWrapper from 'components/SectionWrapper';
import React from 'react';
import { PRIVACY_POLICY } from 'utils/constants/dataTest';
import { PageSectionType } from 'utils/types';

import * as S from './styles';

export type PrivacyPolicyContainerProps = {
  title: string;
  sections: PageSectionType[];
};

const PrivacyPolicyContainer = ({
  title,
  sections,
}: PrivacyPolicyContainerProps): JSX.Element => (
  <Container>
    <S.Wrapper data-testid={PRIVACY_POLICY.CONTAINER}>
      <S.Subtitle>{title}</S.Subtitle>
      <SectionWrapper>
        {sections?.map((section: PageSectionType) => (
          <PrivacyPolicySection key={section.sectionId} section={section} />
        ))}
      </SectionWrapper>
    </S.Wrapper>
  </Container>
);

export default PrivacyPolicyContainer;
