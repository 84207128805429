import { mediaQueries, size, typography } from '@everlywell/leaves';
import styled from 'styled-components';

const Title = styled.div`
  ${typography.h1Text};
`;

const Subtitle = styled.div`
  ${typography.h2Text};
`;

const Wrapper = styled.div`
  margin: ${size.xl1}px 0;

  ${mediaQueries.forTabletHorizontalUp} {
    margin: ${size.xl3}px 0;
  }
`;

export { Title, Subtitle, Wrapper };
