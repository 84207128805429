import React from 'react';
import { PRIVACY_POLICY } from 'utils/constants/dataTest';
import { sanitize } from 'utils/domHelpers';
import { PageSectionType } from 'utils/types';

import * as S from './styles';

export type PrivacyPolicySectionProps = {
  section: PageSectionType;
};

export const PrivacyPolicySection = ({
  section,
}: PrivacyPolicySectionProps): JSX.Element => {
  const description = section.description?.childMarkdownRemark?.html || '';
  return (
    <S.Container data-testid={PRIVACY_POLICY.SECTION}>
      <S.Title>{section.title}</S.Title>

      <S.Description
        dangerouslySetInnerHTML={{
          __html: sanitize(description),
        }}
      ></S.Description>
    </S.Container>
  );
};

export default PrivacyPolicySection;
