import Layout from 'components/Layout';
import { SEO } from 'components/SEO';
import PrivacyPolicyContainer from 'containers/PrivacyPolicyContainer';
import React, { useEffect } from 'react';
import { track } from 'utils/analytics';
import { ANALYTICS } from 'utils/constants/analytics';
import { PageSectionType } from 'utils/types';

type LegalPageProps = {
  pageContext: {
    slug: string;
    page: {
      title: string;
      sections: PageSectionType[];
    };
  };
};

const LegalPage = ({
  pageContext,
}: LegalPageProps): React.ReactElement | null => {
  const { page } = pageContext;

  useEffect(() => {
    track({
      event: ANALYTICS.EVENTS.VIEWED_PAGE,
      data: {
        label: page.title,
      },
    });
  }, [page.title]);

  return (
    <div>
      <SEO title={page.title} />
      <Layout>
        <PrivacyPolicyContainer title={page.title} sections={page.sections} />
      </Layout>
    </div>
  );
};

export default LegalPage;
