import { colors, size, typography } from '@everlywell/leaves';
import styled from 'styled-components';

const Container = styled.div`
  width: 100%;
  margin-bottom: ${size.xl1}px;

  h2 {
    color: ${colors.teal4};
    font-size: 22px;
    line-height: 32px;
    font-weight: ${typography.weight.bold};
    letter-spacing: 0.53px;
    margin: 20px 0 10px;
  }

  h3 {
    color: ${colors.teal4};
    font-size: 20.25px;
    line-height: 28px;
    letter-spacing: 0.23px;
    margin: 30px 0 20px;
  }

  h4 {
    color: ${colors.teal4};
    font-size: 18px;
    margin: 20px 0 10px;
  }
`;

const Title = styled.h1`
  color: ${colors.teal4};
  font-size: 26px;
  line-height: 32px;
  font-weight: ${typography.weight.xbold};
  letter-spacing: 0.4px;
  margin-bottom: 20px;
`;

const Description = styled.div`
  width: 100%;
  ${typography.bodyText};

  p {
    ${typography.bodyTextSmall};
    margin-bottom: 10px;
    line-height: 26px;
  }

  a {
    font-weight: ${typography.weight.bold};
    color: ${colors.green5};
  }

  ul,
  ol {
    list-style-position: inside;
    padding-left: ${size.lg}px;
    margin: ${size.lg}px 0;

    li {
      margin: ${size.md}px 0;
    }
  }

  table {
    border-collapse: collapse;
    border-spacing: 0;
    table-layout: auto;
    width: 100%;
    margin-bottom: 20px;

    td,
    th {
      border: 1px solid ${colors.black};
      padding: ${size.sm}px;
    }

    th {
      color: ${colors.teal4};
      margin: 0;
    }
  }
`;

export { Container, Title, Description };
