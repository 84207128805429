import React, { forwardRef } from 'react';

import * as S from './styles';

interface Props extends React.HTMLAttributes<HTMLElement> {
  children: React.ReactNode;
  id?: string;
  className?: string;
  backgroundColor?: 'Primary' | 'Secondary';
  removePadding?: boolean;
}

export const Section = forwardRef<HTMLElement, Props>((props, ref) => (
  <S.SectionWrapper
    {...props}
    ref={ref}
    className={props.className}
    backgroundColor={props.backgroundColor}
    removePadding={props.removePadding}
  >
    {props.children}
  </S.SectionWrapper>
));

export default Section;
