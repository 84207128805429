import { mediaQueries, size, colors } from '@everlywell/leaves';
import styled from 'styled-components';

const colorMap = {
  Primary: colors.white,
  Secondary: colors.teal1,
};

// 64px of total vertical spacing between sections on mobile, 128px on desktop
export const SectionWrapper = styled.section<{
  backgroundColor?: 'Primary' | 'Secondary';
  removePadding?: boolean;
}>`
  position: relative;
  padding: ${({ removePadding }) => (removePadding ? '' : `${size.xl1}px 0`)};
  background-color: ${({ backgroundColor }) =>
    backgroundColor ? colorMap[backgroundColor] : ''};

  ${mediaQueries.forTabletHorizontalUp} {
    padding: ${size.xl4}px 0;
  }
`;
